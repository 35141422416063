import angular from "angular";

const contactUs = function ($resource) {
  'ngInject';

  return $resource('/api/v1/fo/bicycle-owner/contact-us', {}, {
    send: {
      method: "POST",
      isArray: false
    }
  })
}

export default angular.module('vt-bicycle-owner-resource.contact-us', [])
  .service('BicycleOwnerContactUsApi', contactUs);
