import app from "js/legacy-app";
import angular from "angular";
import mechanicDefaultImage from 'img/mechanic-default-picture.png';
import customerDefaultImage from 'img/user-no-avatar.png';

app.controller('BaseCreateRequestController',
    function ($scope, $location, $routeParams, Notification, ToggleLD, User, $mdDialog, ModalService, $dateTimeService,
              AuthService, HistoryService, $requestStorage, Documents, $requestService, TypeOfBikes, $bikeService, $timeout,
              $typeOfBikesService, $q, Service, PaymentSources, Payments, Organisation, Station, ErrorHandler, LocationService, $window,
              $mdMedia, $pageFooter, $route, RecaptchaService) {

        'ngInject';

        $scope.auth = AuthService;

        var bcrc = {
            bikeOwner: {
                status: ""
            }
        };

        $scope.auth = AuthService;

        $scope.bcrc = {
            wizardOptions: {
                completeOnNext: true,
                onComplete: function () {
                    $scope.bcrc.goToCheckout();
                },
                onClose: function () {
                    $scope.close();
                }
            },
            maxBikes: 100,
            newBike: {},
            bikeId: $routeParams.bikeId,
            organisationId: $routeParams.organisationId,
            stationId: $routeParams.stationId,
            taskCode: $routeParams.taskCode,
            taskId: $routeParams.taskId,
            mechanicId: $routeParams.mechanicId,
            customerId: $routeParams.customerId,
            locationOffset: $dateTimeService.getLocalOffset(),
            currentDate: new Date(),
            timeOptions: $requestService.getTimeOptions,
            fees: 10,
            services: {
                selected: null,
                all: [],
                current: [],
                context: []
            },
            serviceLocation: {},
            appointmentDate: {},
            card: {},
            map: {
                options: {
                    center: {lat: 41.742295, lng: -73.355499}
                },

                markers: []
            },
            tips: $requestService.getTips,
            canvasImages: [],
            request: {
                bikes: []
            },
            customer: {},
            payment: {},
            bicycle: {},
            isBusiness: AuthService.isBikeBusinessSupervisor() || AuthService.isBikeOrganisationOwner(),
            totalPrice: 0,
            servicePrice: 0,
            completedSteps: [],
            disabledSteps: [],
            mechanicDefaultImage
        };

        $scope.bcrc.requestIsGroup = function () {
            var selected = $scope.bcrc.services.selected;
            return selected && selected.group;
        };

        $scope.bcrc.goToCheckout = function () {
            $scope.bcrc.checkoutStage = true;
        };

        $scope.bcrc.goToPaymentDetails = function () {
            $scope.bcrc.paymentStage = true;
        };

        $scope.bcrc.goToWizard = function () {
            $scope.bcrc.checkoutStage = false;
            $scope.bcrc.paymentStage = false;
        };

        $scope.bcrc.goToStep = function (stepName) {
            $scope.bcrc.goToWizard();
            $scope.wizard.goTo(stepName);
        };

        $scope.bcrc.saveRequest = function () {
            ToggleLD.show();
            bcrc.buildRequest();
            bcrc.saveRequest();
        };

        $scope.bcrc.needBike = function () {
            var selected = $scope.bcrc.services.selected;
            return selected && selected.needBike;
        };

        $scope.bcrc.checkUserActivation = function (callback) {
            if ($scope.bcrc.user.activated) {
                return callback();
            }

            User.checkActivation(function (response) {
                $scope.bcrc.user.activated = response.activated;
                if (callback) {
                    callback();
                }
            }, function (error) {
                var defaultError = "Failed to check activation. Please reload page and try again";
                ErrorHandler.onError(error, defaultError);
            });
        };

        $scope.bcrc.goToRegistrationPage = function () {
            $location.path('/sign-up-user');
        };

        $scope.bcrc.goToActivationPage = function () {
            $location.path('/complete-creation-request')
                .search({email: AuthService.user().email});
            AuthService.sendActivationEmail(AuthService.user().email, 'REQUEST_CREATION');
        };

        $scope.bcrc.checkout = function () {
            $scope.bcrc.checkUserActivation(function () {
                if (!AuthService.isFullyLoggedIn()) {
                    $scope.bcrc.saveDraftRequestToLocalStorage();
                    $scope.bcrc.goToRegistrationPage();
                    return;
                }

                if (!$scope.bcrc.user.activated) {
                    $scope.bcrc.saveDraftRequestToLocalStorage();
                    $scope.bcrc.goToActivationPage();
                    return;
                }

                bcrc.loadPaymentDetails().then((hasSufficientFunds) => {
                    if (!hasSufficientFunds && $scope.bcrc.totalPrice > 0) {
                        $scope.bcrc.goToPaymentDetails();
                    } else {
                        $scope.bcrc.formRequest();
                        $scope.bcrc.saveRequest();
                    }
                });

            });
        };

        $scope.bcrc.saveDraftRequestToLocalStorage = function () {
            $scope.bcrc.completedSteps = ['location', 'service', 'mechanics', 'availability'];
            if ($scope.bicyclesStep.options.completed) {
                $scope.bcrc.completedSteps.push('bicycles');
            }
            $scope.bcrc.disabledSteps = [];
            if ($scope.locationStep.disabled) {
                $scope.bcrc.disabledSteps.push('location');
            }
            if ($scope.taskStep.options.disabled) {
                $scope.bcrc.disabledSteps.push('service');
            }
            if ($scope.mechanicsStep.options.disabled) {
                $scope.bcrc.disabledSteps.push('mechanics');
            }
            if ($scope.availabilityStep.options.disabled) {
                $scope.bcrc.disabledSteps.push('availability');
            }
            if ($scope.bicyclesStep.options.disabled) {
                $scope.bcrc.disabledSteps.push('bicycles');
            }

            $scope.bcrc.formRequest();
            var user = AuthService.user();
            var request = {
                bcrc: $scope.bcrc,
                mechanicsStep: $scope.mechanicsStep,
                onlineOnly: $scope.onlineOnly,
                bicyclesStep: $scope.bicyclesStep,
                availabilityStep: $scope.availabilityStep,
                userId: user ? user.id : null
            };

            $requestService.saveDraftRequestToLocalStorage(request);
        };

        $scope.bcrc.pay = function () {
            $scope.paymentForm.$setSubmitted();
            if (!$scope.paymentForm.$valid) {
                return;
            }

            $scope.bcrc.card.fakeNumber = bcrc.buildCardFakeNumber();

            const cardAddRequest = {
                number: $scope.bcrc.card.number,
                cvc: $scope.bcrc.card.cvc,
                name: $scope.bcrc.card.name.toUpperCase(),
                expiryDate: '20' + '' + $scope.bcrc.card.expiry.substr(5, 2) + '-' + $scope.bcrc.card.expiry.substr(0, 2),
                provider: 'Stripe',
                address: {
                    postalCode: $scope.bcrc.card.addressZip
                }

            };

            RecaptchaService.execute('save_payment_source', function (token) {
                ToggleLD.show();
                cardAddRequest['g-recaptcha-response'] = token;
                cardAddRequest['g-recaptcha-action'] = 'save_payment_source';
                PaymentSources.addCard(cardAddRequest, function (data) {
                    ToggleLD.hide();
                    $scope.bcrc.request.paymentsDetails = {
                        source: data.id
                    };
                    $scope.bcrc.formRequest();
                    $scope.bcrc.saveRequest();
                }, function (error) {
                    ToggleLD.hide();
                    ErrorHandler.onError(error, "Unable to add card");
                });
            });

        };

        //Cancel a request
        $scope.close = function () {
            let message = 'Are you sure you want to cancel your request?';
            ModalService.yesNoMessage(null, '', message, function (response) {
                if (!response) {
                    return;
                }

                HistoryService.clear();
                $location.search({});
                if ($routeParams.back === "useBrowserHistory") {
                    $window.history.back();
                }
                if (!$scope.auth.isLogin()) {
                    $location.path('/');
                } else {
                    $location.path('/dashboard');
                }
            });
        };

        $scope.autocomplete = {
            selectedBikes: [],
            showSelectAllFromStationBtn: false,
            selectedOrganisation: {}
        };

        $scope.bcrc.formRequest = function () {
            let location = $scope.bcrc.serviceLocation;
            if (location) {
                $scope.bcrc.request.serviceLocation = LocationService.mapAddress(location);
            }

            $scope.bcrc.request.appointmentDate = $scope.availabilityStep.selectedTime;
        };

        $scope.buildTaskView = function (taskList, taskCode) {
            for (let i = 0; i < taskList.length; i++) {
                if (taskList[i].code === taskCode) {
                    $scope.bcrc.services.selected = taskList[i];
                    return true;
                } else if (taskList[i].nextStep) {
                    $scope.bcrc.services.context.push(taskList[i]);
                    $scope.bcrc.services.current = taskList[i].nextStep.view;
                    let found = $scope.buildTaskView($scope.bcrc.services.current, taskCode);
                    if (!found) {
                        $scope.bcrc.services.context.splice(-1, 1);
                    } else {
                        return true;
                    }
                }
            }
        };

        $scope.$watch('bicyclesStep.selectedBikes.length', function (newValue, oldValue) {
            if (newValue && newValue !== oldValue) {
                bcrc.calculatePrice();
            }
        });

        $scope.$watch('mechanicsStep.selectedMechanic', function (newValue, oldValue) {
            if (newValue !== oldValue) {
                bcrc.calculatePrice();
            }
        });

        $scope.$watch('bcrc.services.selected', function (newVal, oldVal) {
            if (newVal && !angular.equals(newVal, oldVal)) {
                bcrc.calculatePrice();
            }
        });

        $scope.$on('$routeChangeSuccess', function (event, next, current) {
            if (next.segment !== 'create-request') {
                $mdDialog.cancel();
            }
        });

        bcrc.loadPaymentDetails = function () {
            const deferred = $q.defer();

            if (!AuthService.isLogin()) {
                deferred.resolve(false);
            } else {
                ToggleLD.show();
                Payments.hasSufficientFunds({
                    amount: $scope.bcrc.totalPrice,
                    currency: 'USD',
                    productItems: [{
                        id: 'any',
                        productType: 'BICYCLE_SERVICE'
                    }]
                }, function (data) {
                    ToggleLD.hide();
                    deferred.resolve(data.value)
                }, function (error) {
                    ToggleLD.hide();
                    deferred.resolve(false);
                });
            }

            return deferred.promise;

        };

        bcrc.buildCardFakeNumber = function () {
            let number = $scope.bcrc.card.number;
            return '************' + number.substr(number.length - 4);
        };

        bcrc.buildRequest = function () {
            bcrc.setBikesToRequest();
            bcrc.setImagesToRequest();
            bcrc.setOrganisationIdToRequest();
            bcrc.setPriceToRequest();
            bcrc.setServiceToRequest();
            bcrc.setReturnLabelDocumentId();
            bcrc.setReferralLinkToRequest();
        };

        bcrc.setBikesToRequest = function () {
            $scope.bcrc.request.bikes = $scope.bicyclesStep.getBikes();
        };

        bcrc.setImagesToRequest = function () {
            $scope.bcrc.request.images = [];
        };

        bcrc.setOrganisationIdToRequest = function () {
            if (AuthService.isBikeBusinessSupervisor()) {
                $scope.bcrc.request.organisationId = $scope.autocomplete.selectedOrganisation.id;
            }
        };

        bcrc.setPriceToRequest = function () {
            $scope.bcrc.request.price = $scope.bcrc.servicePrice;
        };

        bcrc.setServiceToRequest = function () {
            $scope.bcrc.request.taskId = $scope.mechanicsStep.service.id;
        };

        bcrc.setReturnLabelDocumentId = function () {
            if ($scope.returnLabelStep.imageContainer && $scope.returnLabelStep.imageContainer.images.length) {
                $scope.bcrc.request.returnLabel = $scope.returnLabelStep.imageContainer.images[0].id;
            }
        };

        bcrc.setReferralLinkToRequest = function () {
            $scope.bcrc.request.referralLink = $routeParams['referral-link'];
        };

        bcrc.saveRequest = function () {
            const resource = $requestService.getRequestResource(AuthService);
            resource.save($scope.bcrc.request, function (data) {
                bcrc.handleResponseForCreatedRequest(data);
            }, function (error) {
                bcrc.onError(error);
            });
        };

        bcrc.onError = function (error) {
            ToggleLD.hide();
            let errorCode = ErrorHandler.getErrorCode(error);
            let errorMsg = ErrorHandler.extractError(error);
            if (errorCode === 2001) {
                ModalService.mechanicNotPerformedServiceDialog(errorMsg).then(function (response) {
                    if (!response) return;

                    changeMechanic();
                });
                return;
            }
            if (errorCode === 2002) {
                ModalService.mechanicChangedServicePriceDialog(errorMsg).then(function (response) {
                    if (!response) return;

                    if (response.action === 'CONTINUE') {
                        let mechanicId = $scope.bcrc.request.mechanicId;
                        $scope.mechanicsStep.applyConcreteMechanic(mechanicId, $scope.bcrc.checkout, function () {
                            ErrorHandler.onError(null, "Mechanic does not provide this service any more");
                            changeMechanic();
                        });
                        return;
                    }

                    if (response.action === 'CHANGE_MECHANIC') {
                        changeMechanic();
                    }
                });
                return;
            }

            ErrorHandler.onError(error, "Failed to create request");
        };

        let changeMechanic = () => {
            let selected = $scope.bcrc.services.selected;

            if (isCustomService(selected.taskStepId) && selected.hasCore) {
                $scope.bcrc.services.selected.taskStepId = getCorePartOfCustomService(selected.taskStepId);
                $scope.bcrc.services.selected.code = getCorePartOfCustomService(selected.code);

                let paramsToUpdate = {};

                if ($routeParams.taskCode) {
                    paramsToUpdate.taskCode = $scope.bcrc.services.selected.code;
                }

                if ($routeParams.taskId) {
                    paramsToUpdate.taskId = $scope.bcrc.services.selected.taskStepId;
                }

                if ($routeParams.mechanicId) {
                    paramsToUpdate.mechanicId = null;
                }

                $route.updateParams(paramsToUpdate);

                $scope.bcrc.taskCode = getCorePartOfCustomService($scope.bcrc.taskCode);
                $scope.bcrc.mechanicId = null;
            } else {
                clearConcreteMechanic();
            }

            $scope.bcrc.goToStep('mechanics');
            $scope.mechanicsStep.uncomplete();
        };

        let clearConcreteMechanic = () => {
            $route.updateParams({mechanicId: null});
            $scope.bcrc.mechanicId = null;
        };

        bcrc.handleResponseForCreatedRequest = function (data) {
            ToggleLD.hide();
            HistoryService.clear();
            $location.path('/request/' + data.id).search({info: true});
        };

        bcrc.calculatePrice = function () {
            var countBikes = $scope.bicyclesStep.selectedBikes.length;
            if (countBikes === 0) countBikes = 1;

            var selectedService = $scope.mechanicsStep.service;
            if (!selectedService) {
                return 0;
            }
            $scope.bcrc.servicePrice = selectedService.price;
            var fees = $scope.bcrc.fees;

            let totalPrice = (selectedService.price * countBikes) * (1 + fees / 100);
            $scope.bcrc.totalPrice = totalPrice;
        };

        bcrc.loadUser = function (reload) {
            var deferred = $q.defer();
            AuthService.currentUser(function (user) {
                $scope.bcrc.user = user;
                return deferred.resolve();
            }, reload);
            return deferred.promise;
        };

        bcrc.fetchCustomer = () => {
            let customerId = $scope.bcrc.customerId;
            if (!customerId) {
                return;
            }

            return User.getPublicUserInfo({id1: customerId}, function (user) {
                $scope.bcrc.customer = user;
                if (!$scope.bcrc.customer.avatar || !$scope.bcrc.customer.avatar.thumbnail) {
                    $scope.bcrc.customer.avatar = {
                        thumbnail: customerDefaultImage
                    };
                }
            }).$promise;
        };

        $scope.bcrc.isCompletedStep = function (step) {
            return $scope.bcrc.completedSteps.indexOf(step) !== -1;
        };

        $scope.bcrc.removeStepFromCompleted = function (step) {
            var index = $scope.bcrc.completedSteps.indexOf(step);
            if (index !== -1) {
                $scope.bcrc.completedSteps.splice(index, 1);
            }
        };

        bcrc.getDraftRequestFromLocalStorage = function () {
            var draftRequestJson = $requestStorage.getDraftedRequest('draft-request');
            if (draftRequestJson) {
                $requestService.parseDraftedRequestFromLocalStorage($scope, draftRequestJson);
            }
        };

        bcrc.loadBikeDependencies = function () {
            return $bikeService.loadBikeDescDependencies(function () {
                $scope.bcrc.bicycle.types = $typeOfBikesService.getAll();
            });
        };

        bcrc.initializeTaskStep = function () {
            var deferred = $q.defer();
            var code = $scope.bcrc.taskCode;
            if (code) {
                var setServices = function (data) {
                    deferred.resolve(data);
                    $scope.bcrc.services.all = data.view;
                    $scope.buildTaskView($scope.bcrc.services.all, code);
                    $scope.wizard.disableStep("service");
                    $scope.taskStep.loadServices();
                };

                var taskId = $scope.bcrc.taskId;
                if (!taskId) {
                    Service.getByCode({
                        id1: code,
                        mechanicId: $scope.bcrc.mechanicId,
                        organisationId: $scope.autocomplete.selectedOrganisation
                            ? $scope.autocomplete.selectedOrganisation.id
                            : null
                    }, function (data) {
                        setServices(data);
                    });
                } else {
                    Service.getStep({
                        id1: taskId,
                        mechanicId: $scope.bcrc.mechanicId,
                        organisationId: $scope.autocomplete.selectedOrganisation
                            ? $scope.autocomplete.selectedOrganisation.id
                            : null
                    }, function (data) {
                        setServices(data);
                    });
                }
            } else {
                deferred.resolve();
            }

            return deferred.promise;
        };

        bcrc.needSelectOrganisation = function () {
            var selectedOrganisation = $scope.autocomplete.selectedOrganisation;
            return AuthService.isBikeBusinessSupervisor() &&
                !$scope.bcrc.organisationId &&
                (!selectedOrganisation || selectedOrganisation && !selectedOrganisation.id) &&
                !selectedOrganisation.id &&
                !$scope.bcrc.bikeId;
        };

        bcrc.initCtrl = function () {
            ToggleLD.show();
            $q.all([bcrc.fetchCustomer()].concat(bcrc.loadBikeDependencies())).then(function () {
                if (AuthService.isLogin() && !$requestStorage.hasDraftRequest()) {
                    $requestStorage.removeDraftRequest();
                    bcrc.loadUser(true).then(function () {
                        bcrc.initializeTaskStep();
                        $scope.bcrc.goToStep('location');
                    });
                } else if (AuthService.isLogin() && $requestStorage.hasDraftRequest()) {
                    bcrc.loadUser(true).then(function () {
                        bcrc.getDraftRequestFromLocalStorage();
                        bcrc.initializeTaskStep();
                        var requestCreatorId = $scope.bcrc.requestCreatorId;
                        if (!requestCreatorId && !AuthService.isUser()) {
                            $scope.bcrc.goToStep('location');
                            return;
                        }

                        if (requestCreatorId && AuthService.user().id !== requestCreatorId) {
                            $scope.bcrc.goToStep('location');
                            return;
                        }

                        var options = $scope.bicyclesStep.options;
                        if ($scope.bcrc.needBike() && !options.completed && !options.disabled) {
                            $scope.bcrc.goToStep('bicycles');
                        } else {
                            $scope.bcrc.goToCheckout();
                        }
                    });
                } else {
                    $timeout(function () {
                        bcrc.initializeTaskStep();
                        $scope.bcrc.goToStep('location');
                    });
                }
            }).catch(function (error) {
                ErrorHandler.onError(error, "Something went wrong");
            }).finally(function () {
                ToggleLD.hide();
            });
        };

        let isCustomService = (service) => {
            if (!service) {
                return false;
            }

            return service.split('.').length === 2;
        };

        let getCorePartOfCustomService = (service) => {
            if (isCustomService(service)) {
                return service.split('.')[1];
            }

            return service;
        };

        (function init() {
            if ($mdMedia('(max-width: 470 px)')) {
                $pageFooter.hide();
            }
            $timeout(function () {
                if (bcrc.needSelectOrganisation()) {
                    let serviceBucket = $routeParams.serviceBucket;
                    ModalService.selectOrganisation(null, serviceBucket).then(function (res) {
                        if (res === 'close') return;
                        $scope.autocomplete.selectedOrganisation = res;
                        bcrc.initCtrl();
                    });
                    return;
                }

                bcrc.initCtrl();
            });
        })();
    });
