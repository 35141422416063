import "./form-image.component.scss";

export default {
  template: require('./form-image.component.html'),
  bindings: {
    images: "<?vuiImages",
    onClick: "<?vuiClick"
  },
  controller: function () {
    'ngInject';

    const self = this;

    self.pagination = {
      setPage: (page) => {
        self.pagination.current = page;
      },
      current: 0
    }
  }
};
