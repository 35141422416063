import "./calendar.component.scss";
import moment from "moment";

export default {
  template: require('./calendar.component.html'),
  bindings: {
    schedule: '=vuiSchedule',
    onSelected: '<?vuiOnSelected'
  },
  controller: function ($t) {
    'ngInject';

    const self = this;

    self.thisYear = new Date().getFullYear();
    self.daysOfWeek = [
      $t('shared.days_of_week_short.sun'),
      $t('shared.days_of_week_short.mon'),
      $t('shared.days_of_week_short.tue'),
      $t('shared.days_of_week_short.wed'),
      $t('shared.days_of_week_short.thu'),
      $t('shared.days_of_week_short.fri'),
      $t('shared.days_of_week_short.sat')
    ];
    self.months = [
      $t('shared.months_full.jan'),
      $t('shared.months_full.feb'),
      $t('shared.months_full.mar'),
      $t('shared.months_full.apr'),
      $t('shared.months_full.may'),
      $t('shared.months_full.jun'),
      $t('shared.months_full.jul'),
      $t('shared.months_full.aug'),
      $t('shared.months_full.sept'),
      $t('shared.months_full.oct'),
      $t('shared.months_full.nov'),
      $t('shared.months_full.dec')
    ];

    self.selectDate = day => {
      self.schedule.selectedDate = day.date;
      if (self.onSelected) {
        self.onSelected(day);
      }
    }

    self.schedule.setCurrentMonth = (month, year) => {
      self.schedule.currentMonth = month;
      self.schedule.currentYear = year;
      self.days = buildMonth(month, year);
    }

    self.prevMonth = () => {
      if (!self.hasPrevMonth) {
        return;
      }

      if (self.schedule.currentMonth === 0) {
        self.schedule.setCurrentMonth(11, self.schedule.currentYear - 1)
      } else {
        self.schedule.setCurrentMonth(self.schedule.currentMonth - 1, self.schedule.currentYear)
      }
    }

    self.nextMonth = () => {
      if (!self.hasNextMonth) {
        return;
      }

      if (self.schedule.currentMonth === 11) {
        self.schedule.setCurrentMonth(0, self.schedule.currentYear + 1)
      } else {
        self.schedule.setCurrentMonth(self.schedule.currentMonth + 1, self.schedule.currentYear)
      }
    }

    const buildMonth = (month, year) => {
      let currentDate = new Date();
      currentDate.setDate(1);
      currentDate.setMonth(month);
      currentDate.setFullYear(year);

      // search start of calendar
      while (true) {
        if (currentDate.getDay() === 0) {
          break;
        }

        currentDate.setDate(currentDate.getDate() - 1)
      }

      // search last  day
      const finalDate = new Date(currentDate);
      finalDate.setDate(1);
      finalDate.setMonth(month);
      finalDate.setFullYear(year)
      finalDate.setMonth(finalDate.getMonth() + 1);
      finalDate.setDate(0);
      while (finalDate.getDay() !== 6) {
        finalDate.setDate(finalDate.getDate() + 1)
      }

      const days = [];

      while (currentDate.getTime() <= finalDate.getTime()) {
        const availability = self.schedule.availability
          ? self.schedule.availability[moment(currentDate).format('YYYY-MM-DD')] : {};

        days.push({
          label: currentDate.getDate(),
          date: currentDate,
          enabled: self.schedule.currentMonth === currentDate.getMonth()
            && availability && self.schedule.availability && availability.available,
          timeSlots: availability ? availability.timeSlots : []
        });
        currentDate = new Date(currentDate);
        currentDate.setDate(currentDate.getDate() + 1);
      }

      currentDate.setDate(1);
      currentDate.setMonth(month);
      currentDate.setFullYear(year);
      currentDate.setDate(0);
      self.hasPrevMonth = self.schedule.minDate ? currentDate.getTime() > new Date(self.schedule.minDate.date).getTime() : true;

      currentDate.setDate(1);
      currentDate.setMonth(month + 1);
      currentDate.setFullYear(year);
      self.hasNextMonth = self.schedule.maxDate ? currentDate.getTime() < new Date(self.schedule.maxDate.date).getTime() : true;

      return days;
    }

    self.schedule.refresh = () => {
      self.schedule.currentMonth = new Date().getMonth();
      self.schedule.currentYear = new Date().getFullYear();
      self.days = buildMonth(self.schedule.currentMonth, self.schedule.currentYear);

      let tick = 0;
      while (!self.days.find(day => day.enabled)) {
        self.nextMonth();
        tick++;
        if (tick === 5) {
          return;
        }
      }
    }

    self.schedule.refresh();

  }
};
