import './contact-us.modal.scss';

export default function ContactUsModal($scope, close) {
  'ngInject';

  $scope.contactUs = {
    message: ''
  };

  $scope.ok = (form) => {
    if (form.$valid) {
      close($scope.contactUs.message);
    }
  };

  $scope.close = () => {
    close();
  };

}