import angular from "angular";

export default function NotificationsWsServiceProvider() {
  this.$get = ($timeout, AuthService) => {
    'ngInject';

    function NotificationsWsService() {

      const self = this;

      self.REGISTRATION_CODE = 0;
      self.READ_NOTIFICATION_CODE = 1;
      self.READ_NOTIFICATIONS_CODE = 2;
      self.MARK_ALL_READ_CODE = 3;
      self.LOAD_MORE_CODE = 4;
      self.REMOVE_NOTIFICATION_CODE = 5;
      self.PING_CODE = 6;
      self.READ_ALL_NOTIFICATIONS_CODE = 7;

      let ws = undefined;
      let closeWS = false;
      let connection = undefined;
      const handlers = {};

      const pingWs = () => {
        setTimeout(() => {
          if (closeWS === false && ws && ws.readyState === ws.OPEN) {
            ws.send(angular.toJson({operation: self.PING_CODE}));
            pingWs();
          }
        }, 55 * 1000);
      };

      const reconnect = timeoutSeconds => $timeout(() => {
        self.connect();
      }, timeoutSeconds * 1000);

      const checkStatusConnection = connected => {
        if (angular.isDefined(connection) && angular.isFunction(connection)) {
          connection(connected);
        }
      }

      self.connect = () => {
        if (ws) {
          return;
        }

        if (!ws) {
          const protocol = location.protocol === 'https:' ? 'wss://' : 'ws://';
          const socketUrl = protocol + document.location.host + '/ws/v1/fo/notifications';
          ws = new WebSocket(socketUrl);

          ws.onclose = evt => {
            checkStatusConnection(false);
            if (closeWS === true) {
              ws = null;
              closeWS = false;
            } else {
              ws = null;
              reconnect(15);
            }
          };

          ws.onmessage = evt => {
            const data = angular.fromJson(evt.data);
            const handler = handlers[data.type];
            if (handler) {
              for (let i = 0; i < handler.length; i++) {
                handler[i].fn(data);
              }
            }
          };

          ws.onopen = evt => {
            ws.send(angular.toJson({
              operation: self.REGISTRATION_CODE,
              token: AuthService.getToken().access_token
            }));
            pingWs();
            checkStatusConnection(true);
          };

          ws.onerror = evt => checkStatusConnection(false);
        }
      };

      self.close = () => {
        closeWS = true;
        if (ws && ws.CLOSED !== ws.readyState) {
          ws.close();
        }
      };

      self.send = data => {
        if (ws && ws.readyState === ws.OPEN) {
          data.token = AuthService.getToken().access_token;
          ws.send(angular.toJson(data));
        }
      };

      self.readNotification = notificationId => {
        const request = {
          operation: self.READ_NOTIFICATION_CODE,
          notificationId: notificationId,
          token: AuthService.getToken().access_token
        };
        self.send(request);
      };

      self.registerHandler = (id, label, fn) => {
        const handler = handlers[id];

        if (handler) {
          let found = false;

          for (let i = 0; i < handler.length; i++) {
            if (handler[i].label === label) {
              found = true;
              handler[i] = {
                label: label,
                fn: fn
              };

              break;
            }
          }

          if (!found) {
            handler.push({
              label: label,
              fn: fn
            });
          }
        } else {
          handlers[id] = [{
            label: label,
            fn: fn
          }];
        }
      };

      self.unregisterHandler = (id, label) => {
        const handler = handlers[id];

        if (!handler) {
          return;
        }

        for (let i = 0; i < handler.length; i++) {
          if (handler[i].label === label) {
            handler.splice(i, 1);
            if (handler.length === 0) {
              delete handlers[id];
            }

            break;
          }
        }
      };

      self.registerInternetConnectionHandler = fn => {
        connection = fn;
      };

      self.unregisterInternetConnectionHandler = () => {
        connection = undefined;
      };

    }

    return new NotificationsWsService();
  };
}