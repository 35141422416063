export default function ($routeSegmentProvider) {

  "ngInject";

  $routeSegmentProvider
    .when('/bicycle-owner/bicycles', 'bicycle-owner.bicycles-list')
    .when('/bicycle-owner/bicycles/:id', 'bicycle-owner.bicycle-info')
    .when('/bicycle-owner/bicycles/:id/transfer', 'bicycle-owner.bicycle-transfer')

    .within('bicycle-owner')

    .segment('bicycles-list', {
      access: {requireLogin: true},
      breadcrumb: [{
        label: 'bicycle_owner.breadcrumb.bicycles'
      }],
      template: '<bicycle-owner-bicycles-list-page></bicycle-owner-bicycles-list-page>',
      loadModule: () => {
        return import(/* webpackChunkName: "bicycle-owner-bicycles-list.module" */ "./list/bicycle-owner-bicycles-list.module");
      }
    })
    .segment('bicycle-info', {
      access: {requireLogin: true},
      breadcrumb: [
        {
          label: 'bicycle_owner.breadcrumb.bicycles',
          url: '/bicycle-owner/bicycles'
        },
        {
          label: 'bicycle_owner.breadcrumb.bicycle_info'
        }
      ],
      template: '<bicycle-owner-bicycle-info-page></bicycle-owner-bicycle-info-page>',
      loadModule: () => {
        return import(/* webpackChunkName: "bicycle-owner-bicycle-info.module" */ "./info/bicycle-owner-bicycle-info.module");
      }
    })
    .segment('bicycle-transfer', {
      access: {requireLogin: true},
      breadcrumb: [
        {
          label: 'bicycle_owner.breadcrumb.bicycles',
          url: '/bicycle-owner/bicycles'
        },
        {
          label: 'bicycle_owner.breadcrumb.bicycle_info'
        }
      ],
      template: '<bicycle-owner-bicycle-transfer-page></bicycle-owner-bicycle-transfer-page>',
      loadModule: () => {
        return import(/* webpackChunkName: "bicycle-owner-bicycle-transfer.module" */ "./transfer/bicycle-owner-bicycle-transfer.module");
      }
    })
}
