export default function ($routeSegmentProvider) {

  "ngInject";

  $routeSegmentProvider
    .when('/bicycle-owner/notifications', 'bicycle-owner.notifications')

    .within('bicycle-owner')

    .segment('notifications', {
      access: {requireLogin: true},
      breadcrumb: [{
        label: 'bicycle_owner.breadcrumb.notifications'
      }],
      template: '<bicycle-owner-notifications-list-page></bicycle-owner-notifications-list-page>',
      loadModule: () => {
        return import(/* webpackChunkName: "bicycle-owner-notifications-list.module" */ "./list/bicycle-owner-notifications-list.module");
      }
    })
}
