import "./bicycle-owner-root.component.scss";

export default {
  template: require('./bicycle-owner-root.component.html'),
  controller: function ($t, AuthService, ToggleLD, Notification, BicycleOwnerContactUsApi, vuiModals) {
    'ngInject';

    const self = this;

    self.auth = AuthService;

    self.submitContactUs = message => {
      ToggleLD.show();
      BicycleOwnerContactUsApi.send({body: message},
        response => {
          const successMessage = $t('shared.modals.contact_us.sending_success_message');
          vuiModals.info(successMessage);
          ToggleLD.hide();
        },
        error => {
          ToggleLD.hide();
          Notification.error(error.data.message || $t('public.about.contact_us.send_message_error_message'));
        })
    }
  }
};
