import angular from "angular";

import "./form-images-list.component.scss";

export default {
  template: require('./form-images-list.component.html'),
  bindings: {
    images: "=?vuiImages",
    management: "<?vuiManagement"
  },
  controller: function ($element, $timeout) {
    'ngInject';

    const self = this;

    self.photosListPosition = 1;
    self.sizeClass = 'vui-form-images-list--' + (self.management.size ? self.management.size : 's');
    const stepSize = self.management.size === 's' ? 58 : 212;

    const findElemByClass = (className) => {
      const list = $element.find('div')
      for (let i = 0; i < list.length; i++) {
        if (list[i].className.indexOf(className) >= 0) {
          return angular.element(list[i]);
        }
      }
    }

    let $container;
    let $list;

    self.management.recalculate = () => {
      $timeout(() => {
        if (!$container) {
          $container = findElemByClass('scrollable-list');
        }

        if (!$list) {
          $list = findElemByClass('vui-form-images-list__photos');
        }

        self.showImagesNavigations = $container && $list
          && ($list[0].offsetWidth - $container[0].offsetWidth > 1);
        recalculate();
      })
    }

    const recalculate = () => {
      if ($container && $list) {
        const maxOffset = $container[0].offsetWidth - $list[0].offsetWidth;

        self.enabledPrev = self.photosListPosition < 0;
        self.enabledNext = self.photosListPosition > maxOffset;

        if (self.showImagesNavigations) {
          $timeout(() => {
            const maxWidth = $element[0].offsetWidth - 2 * 24;
            $container[0].style.maxWidth = maxWidth + 'px';
          });
        }
      }
    }

    self.management.recalculate();

    self.showNextPhotos = (e) => {
      self.photosListPosition -= stepSize;
      const maxOffset = $container[0].offsetWidth - $list[0].offsetWidth;
      if (self.photosListPosition < maxOffset) {
        self.photosListPosition = maxOffset;
      }
      self.photosTranslate = 'translateX(' + self.photosListPosition + 'px)';
      recalculate();
    }

    self.showPrevPhotos = (e) => {
      self.photosListPosition += stepSize;
      if (self.photosListPosition > -1) {
        self.photosListPosition = -1;
      }
      self.photosTranslate = 'translateX(' + self.photosListPosition + 'px)';
      recalculate();
    }


  }
};
