export default function PageableServiceProvider() {
  this.$get = ($location) => {
    'ngInject';

    function PageableService() {

      const self = this;

      const buildURLQuery = obj =>
        Object.entries(obj)
          .map(pair => pair.map(encodeURIComponent).join('='))
          .join('&');

      const buildLink = (path, params, page) => {
        params.page = page;
        return path + '?' + buildURLQuery(params);
      }

      self.calculate = (total, current, size) => {
        if (total === 0) {
          return {
            total: total,
            current: current,
            size: size,
            hasNext: false,
            hasPrev: false,
            pages: []
          }
        }

        const path = $location.path();
        const params = $location.search() || {};

        const countOfPageItems = 7;

        const response = {
          total: total,
          current: current,
          size: size
        };

        const countOfPages = Math.ceil(total / size);

        response.hasNext = current !== (countOfPages - 1);
        response.hasPrev = current !== 0;
        if (response.hasNext) {
          response.nextPageLink = buildLink(path, params, current + 1);
        }
        if (response.hasPrev) {
          response.prevPageLink = buildLink(path, params, current - 1);
        }

        const allPages = [];

        for (let i = 0; i < countOfPages; i++) {
          allPages.push({
            number: i,
            label: i + 1 + '',
            more: false,
            current: current === i
          })
        }

        if (allPages.length <= countOfPageItems) {
          response.pages = allPages;
        } else {
          const countOfSideItems = (countOfPageItems - 1) / 2;
          const leftCount = current;
          const rightCount = countOfPages - current - 1;

          let expectedLeftCount = countOfSideItems;
          let expectedRightCount = countOfSideItems
          if (current < countOfSideItems) {
            expectedLeftCount = current;
            expectedRightCount = countOfPageItems - expectedLeftCount - 1;
          } else if ((countOfPages - current - 1) < countOfSideItems) {
            expectedRightCount = countOfPages - current - 1;
            expectedLeftCount = countOfPageItems - expectedRightCount - 1;
          }

          const pages = [];

          for (let i = current - 1; pages.length < expectedLeftCount && i >= 0; i--) {
            pages.unshift(allPages[i]);
          }
          pages.push(allPages[current]);
          for (let i = current + 1; i < allPages.length && (pages.length - expectedLeftCount - 1) < expectedRightCount; i++) {
            pages.push(allPages[i]);
          }

          if (leftCount > expectedLeftCount) {
            pages[0] = allPages[0];
            pages[1] = {
              number: 1,
              label: '...',
              more: true,
              current: false
            };
          }

          if (rightCount > expectedRightCount) {
            pages[pages.length - 1] = allPages[allPages.length - 1];
            pages[pages.length - 2] = {
              number: pages.length - 2,
              label: '...',
              more: true,
              current: false
            };
          }

          response.pages = pages;
        }

        response.pages.forEach(item => {
          if (!item.current && !item.more) {
            item.link = buildLink(path, params, item.number);
          }
        })

        return response;
      }

      self.isChangedUrl = (current, next) => {
        return current.split('?')[0] !== next.split('?')[0];
      }

      self.isChangedParams = (current, next) => {
        const currentParams = self.parseQuery(current)
        const nextParams = self.parseQuery(next);

        delete currentParams.page;
        delete nextParams.page;

        return JSON.stringify(currentParams) === JSON.stringify(nextParams);
      }

      self.parseQuery = (url) => {
        const paramsStr = url.split('?')[1];
        return paramsStr ? JSON.parse('{"' + decodeURI(paramsStr)
          .replace(/"/g, '\\"')
          .replace(/&/g, '","')
          .replace(/=/g, '":"') + '"}') : {};
      }
    }

    return new PageableService();
  };
}