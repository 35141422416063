import "./form-image-pagination.component.scss";

export default {
  template: require('./form-image-pagination.component.html'),
  bindings: {
    total: "=?vuiTotal",
    pagination: "=?vuiPagination"
  },
  controller: function () {
    'ngInject';

    const self = this;

    self.pagination.buildPages = (current) => {
      const allPages = [];
      const countOfPages = Math.max(self.total, 5);
      const countOfPageItems = 5;

      for (let i = 0; i < countOfPages; i++) {
        allPages.push({
          number: i,
          active: current === i,
          show: false
        })
      }

      let startToShow = current - 1;
      if (current === 0) {
        startToShow = 0;
      } else if (current === self.total - 1) {
        startToShow = Math.max(self.total - 3, 0)
      }
      for (let i = startToShow; i < startToShow + 3 && i < self.total; i++) {
        allPages[i].show = true;
      }

      let pages = [];

      if (allPages.length <= countOfPageItems) {
        pages = allPages;
      } else {
        const countOfSideItems = (countOfPageItems - 1) / 2;
        let expectedLeftCount = countOfSideItems;
        let expectedRightCount = countOfSideItems
        if (current < countOfSideItems) {
          expectedLeftCount = current;
          expectedRightCount = countOfPageItems - expectedLeftCount - 1;
        } else if ((countOfPages - current - 1) < countOfSideItems) {
          expectedRightCount = countOfPages - current - 1;
          expectedLeftCount = countOfPageItems - expectedRightCount - 1;
        }

        for (let i = current - 1; pages.length < expectedLeftCount && i >= 0; i--) {
          pages.unshift(allPages[i]);
        }
        pages.push(allPages[current]);
        for (let i = current + 1; i < allPages.length && (pages.length - expectedLeftCount - 1) < expectedRightCount; i++) {
          pages.push(allPages[i]);
        }
      }

      self.pages = pages;
    }

    self.onChangePage = (nextPage) => {
      const nextPageNumber = self.pages[nextPage].number;
      if (nextPageNumber !== self.pagination.current) {
        self.pagination.buildPages(nextPageNumber);
        self.pagination.setPage(nextPageNumber);
      }
    }

    self.pagination.buildPages(self.pagination.current);

  }
};
