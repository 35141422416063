import './main-menu-help-item.component.scss';

export default {
  template: require('./main-menu-help-item.component.html'),
  bindings: {
    onSubmit: "<?vuiSubmit",
  },
  controller: function (vuiModals) {
    'ngInject';

    const self = this;

    self.onClick = () => {
      vuiModals.contactUs(result => {
        if (result) {
          self.onSubmit(result);
        }
      })
    }
  }
};
