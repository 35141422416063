export default function ($routeSegmentProvider) {

  "ngInject";

  $routeSegmentProvider
    .when('/bicycle-owner/profile/personal-info', 'bicycle-owner.personal-info')
    .when('/bicycle-owner/profile/security', 'bicycle-owner.settings')

    .within('bicycle-owner')

    .segment('personal-info', {
      access: {requireLogin: true},
      breadcrumb: [{
        label: 'bicycle_owner.breadcrumb.profile'
      }],
      template: '<bicycle-owner-personal-info-page></bicycle-owner-personal-info-page>',
      loadModule: () => {
        return import(/* webpackChunkName: "bicycle-owner-personal-info.module" */ "./personal-info/bicycle-owner-personal-info.module");
      }
    })

    .segment('settings', {
      access: {requireLogin: true},
      breadcrumb: [{
        label: 'bicycle_owner.breadcrumb.security'
      }],
      template: '<bicycle-owner-security-page></bicycle-owner-security-page>',
      loadModule: () => {
        return import(/* webpackChunkName: "bicycle-owner-security.module" */ "./security/bicycle-owner-security.module");
      }
    });
}
