import './after-create-request.modal.scss';

export default function AfterCreateRequestModal($scope, close) {
  'ngInject';

  $scope.openContactUs = () => {
    close(true);
  }

  $scope.ok = () => {
    close(false);
  }
}