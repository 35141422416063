import './tab.component.scss';

export default {
  template: require('./tab.component.html'),
  transclude: true,
  bindings: {
    href: "@?vuiHref",
    active: "<?active",
    disabled: "<?vuiDisabled"
  },
  controller: function () {
    'ngInject';

    const self = this;

    self.getClass = () => {
      if (self.disabled) {
        return 'disabled';
      } else {
        return self.active !== undefined && self.active !== false ? 'active' : '';
      }
    }

  }
};
