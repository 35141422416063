import './main-menu-notifications-item.component.scss';

export default {
  template: require('./main-menu-notifications-item.component.html'),
  bindings: {
    href: "@?vuiHref",
    disabled: "<?vuiDisabled"
  },
  controller: function ($scope, vuiNotificationsService) {
    'ngInject';

    const NOTIFICATION_HANDLER_ID = 'main-menu';

    const self = $scope.$ctrl;

    self.notifications = {};

    const notificationHandler = payload => $scope.$apply(() => {
      self.notifications = payload;
      self.hasNotifications = self.notifications.count !== 0;
    });

    vuiNotificationsService.registerHandler(NOTIFICATION_HANDLER_ID, notificationHandler);
    vuiNotificationsService.connect();
    $scope.$on('$destroy', () => {
      vuiNotificationsService.unregisterHandler(NOTIFICATION_HANDLER_ID);
      vuiNotificationsService.close();
    });

  }
};
