import './info.modal.scss';

export default function InfoModal ($scope, close, message) {
  'ngInject';

  $scope.message = message;

  $scope.close = () => {
    close();
  }
}