import './main-menu-profile-item.component.scss';
import DefaultImage from "img/ic_no_avatar.svg";

export default {
  template: require('./main-menu-profile-item.component.html'),
  bindings: {
    href: "@?vuiHref",
  },
  controller: function (AuthService) {
    'ngInject';

    this.auth = AuthService;

    this.getFullName = () => {
      if (AuthService.user()) {
        return AuthService.user().name + ' ' + AuthService.user().surname;
      } else {
        return ''
      }
    }

    this.getUserAvatar = () => {
      if (AuthService.user() && AuthService.user().avatar) {
        return AuthService.user().avatar.thumbnail;
      }

      return DefaultImage;
    };
  }
};
