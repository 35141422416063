import angular from "angular";

import './more-button.component.scss';

export default () => {
  return {
    restrict: 'E',
    replace: true,
    transclude: {
      button: "?vuiButtonContent",
      actions: "?vuiMoreActions"
    },
    template: require('./more-button.component.html'),
    link: (scope, element) => {

      const findElemByClass = (className) => {
        const list = element.find('div')
        for (let i = 0; i < list.length; i++) {
          if (list[i].className.indexOf(className) >= 0) {
            return angular.element(list[i]);
          }
        }
      }

      const dropdown = findElemByClass('vui-select__dropdown')

      scope.open = () => {
        element.addClass('vui-input-container--opened');
        const rect = element[0].getClientRects()[0] | element[0].getBoundingClientRect()[0];

        const position = calculatePosition(rect.bottom + dropdown[0].offsetHeight > window.innerHeight, dropdown[0])
        dropdown.css('margin-top', position.top + 'px');
        if (dropdown[0].offsetLeft + position.left > 0) {
          dropdown.css('margin-left', position.left + 'px');
        } else {
          dropdown.css('margin-left', '0px');
        }

        if (document.body.clientWidth < (dropdown[0].getBoundingClientRect().x + dropdown[0].offsetWidth)) {
          dropdown.css('right', '0px');
        }
      }

      const calculatePosition = (isBottom, dropdown) => {
        if (window.innerWidth > 1400) {
          const scrollTop = document.getElementsByClassName('vui-content')[0].scrollTop;
          return {
            left: dropdown.parentElement.offsetWidth - 8,
            top: 4 - scrollTop
          };
        } else {
          const scrollTop = document.getElementsByClassName('vui-content')[0].scrollTop;
          return {
            left: dropdown.parentElement.offsetWidth - dropdown.offsetWidth / 2 - 20,
            top: 4 - scrollTop
          };
        }
      }

      scope.close = () => {
        element.removeClass('vui-input-container--opened')
      }
    }
  };
}
