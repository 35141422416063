import './side-menu-item.component.scss';

export default {
  template: require('./side-menu-item.component.html'),
  bindings: {
    onClick: "=?vuiClick",
    href: "@?vuiHref",
    segment: "@?vuiSegment",
    disabled: "<?vuiDisabled"
  },
  transclude: true,
  controller: function ($routeSegment, $location, $scope) {
    'ngInject';

    const self = this;

    const onLoadUrl = () => self.isActive = $location.url().includes(self.segment);

    $scope.$on('$locationChangeSuccess', onLoadUrl)
    onLoadUrl();
  }
};
