import angular from "angular";

import VtSharedModule from '../../../vt-shared/vt-shared.module';
import BicycleOwnerModuleRouting from './bicycle-owner-root.module.routing';
import BicycleOwnerRootComponent from "./bicycle-owner-root.component";
import BicycleOwnerContactUsApi from '../../resources/contact-us/bicycle-owner-contct-us.resource';

export default angular.module("vt-bicycle-owner-root.module", [
  VtSharedModule.name,
  BicycleOwnerContactUsApi.name
])
  .config(BicycleOwnerModuleRouting)
  .component("bicycleOwnerPage", BicycleOwnerRootComponent);
