export default function ($routeSegmentProvider) {

  "ngInject";

  $routeSegmentProvider
    .when('/bicycle-owner/complete-registration', 'bicycle-owner.complete-registration')

    .within('bicycle-owner')

    .segment('complete-registration', {
      access: {requireLogin: true},
      breadcrumb: [{
        label: 'bicycle_owner.breadcrumb.complete_registration'
      }],
      template: '<bicycle-owner-complete-registration-page></bicycle-owner-complete-registration-page>',
      loadModule: () => {
        return import(/* webpackChunkName: "bicycle-owner-complete-registration.module" */ "./complete-registration/bicycle-owner-complete-registration.module");
      }
    });
}
