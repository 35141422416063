import app from "js/legacy-app";

app.factory("accessCheckingService", ['AuthService', '$q', '$location', 'Security', '$routeParams', function (AuthService, $q, $location, Security, $routeParams) {
    return {
        check: function (access) {
            var deferred = $q.defer();
            var logoutRequired = access && access.requireLogout;
            if (logoutRequired) {
                AuthService.logout(true, function () {
                    deferred.resolve();
                });
            } else {
                AuthService.refreshAuthentication().then(function (user) {
                    var currentUrl = encodeURIComponent($location.url());
                    var loginRequired = (!access || access.requireLogin);

                    if (loginRequired && !AuthService.isLogin()) {
                        deferred.reject();
                        $location.path('/login').search({'redirect': currentUrl});
                    } else if (loginRequired && AuthService.isPartiallyRegistered() && user.signUpType === 'LOCAL') {
                        deferred.reject();

                        redirectToCompleteRegistration(user, currentUrl);
                    } else {
                        Security.check($location.path(), $routeParams);
                        deferred.resolve();
                    }
                });
            }
            return deferred.promise;
        }
    };

    function redirectToCompleteRegistration(user, currentUrl) {
        $location.path('/complete-registration').search({
            'redirect': currentUrl,
            'fn': user.name,
            'sn': user.surname
        });
    }
}]);
