import "./images-gallery.component.scss";

export default {
  template: require('./images-gallery.component.html'),
  bindings: {
    images: "=?vuiImages",
    gallery: "=vuiGallery"
  },
  controller: function ($timeout) {
    'ngInject';

    const self = this;

    self.gallery.show = false;

    self.gallery.open = (page) => {
      self.pagination.setPage(page);
      self.gallery.show = true;
      $timeout(() => self.pagination.buildPages(page))
    }

    self.gallery.close = () => {
      self.gallery.show = false;
    }

    self.pagination = {
      setPage: (page) => {
        self.pagination.current = page;
      },
      next: () => {
        if (self.pagination.current < self.images.length - 1) {
          self.pagination.setPage(self.pagination.current + 1)
          self.pagination.buildPages(self.pagination.current)
        }
      },
      prev: () => {
        if (self.pagination.current > 0) {
          self.pagination.setPage(self.pagination.current - 1)
          self.pagination.buildPages(self.pagination.current)
        }
      },
      current: 0
    }
  }
};
