import angular from 'angular';

(function () {

    'use strict';

    if (!String.prototype.startsWith) {
        String.prototype.startsWith = function (searchString, position) {
            position = position || 0;
            return this.substr(position, searchString.length) === searchString;
        };
    }

    function PhoneService() {
        var phones = {
            'US': 1,	// (1)-N*3-N*2-10
            'AT': 43,   // (43)-
            'AU': 61,	// (61)-N*10
            'BE': 32,	// (32)-N*8
            'BR': 55,   // (55)-N*11
            'BY': 37,	// (45)-N*8
            'CA': 1,	// (1)-N*3-N*2-10
            'CH': 41,	// (41)-n*9
            'DE': 49,	// (49)-N*9
            'DK': 45,	// (45)-N*8
            'EE': 372,
            'ES': 34,	// (34)-N*9
            'FI': 358,
            'FR': 33,	// (33)-N*9
            'GB': 44,	// (44)-N*10
            'GR': 30,
            'IE': 353,	// (353)-N*9
            'IT': 39,	// (39)-n*9
            'LT': 370,
            'LU': 352,
            'LV': 371,
            'MY': 60,
            'MX': 52,
            'NL': 31,	// (31)-N*93
            'NO': 47,
            'NZ': 64,
            'PL': 48,
            'PT': 351,
            'SE': 46,
            'SI': 386,
            'SK': 421,
        };

        var getCountries = function () {
            var countries = [];

            for (var c in phones) {

                countries.push({
                    id: c,
                    value: phones[c]
                });
            }

            return countries;
        };

        var getCountryById = function (id) {

            return {
                id: id,
                value: phones[id]
            };
        };

        var setCountry = function (country, scope) {
            scope.selectedCountry = getCountryById(country);
        };

        var getNumberPrefix = function (country) {
            if ((country) === 'CA')
                return '+(' + phones[country] + ') ';
            return '+ (' + phones[country] + ') ';
        };

        var setNumberPrefix = function (country, scope) {
            if (scope.profile) {
                scope.profile.user.phone = getNumberPrefix(country);
                scope.$apply();
            } else {
                scope.user.phone = getNumberPrefix(country);
            }
        };

        var getCountryFromNumber = function (number) {
            if (number.startsWith('+(', 0))
                return 'CA';

            number = number.replace(/\D/g, '');
            /* Remove all not a number symbols */

            for (var c in phones) {
                if (number.startsWith(phones[c], 0)) {
                    return c;
                }
            }

            return 'US';
        };

        var validateNumberByLength = function (number, scope) {
            var startString = number.indexOf(') ');
            if (number.length - startString > 7) {
                scope.isValidNumber = true;
            } else scope.isValidNumber = number.length - startString === 2;
        };

        var validateNumber = function (number, minlength) {
            var trimmed = number.replace(/\s/g, '');
            var countryCodeEndPosition = trimmed.indexOf(')');
            return trimmed.length - countryCodeEndPosition > minlength;
        };

        var formNumber = function (country, number, changed, prevCountry) {
            var numberPrefix = getNumberPrefix(country);

            number = number.replace(/\D/g, '');
            /* Remove all not a number symbols */

            if (!number.length)
                return numberPrefix;

            if (number.length > 15) {
                number = number.substr(0, 15);
            }

            number = number.split('');
            /* To Array */

            if (!changed) {
                //Add code country separators
                if (getCountyType(country) === 1)
                    number.splice(0, 1, numberPrefix);
                else if (getCountyType(country) === 2)
                    number.splice(0, 3, numberPrefix);
                else if (getCountyType(country) === 3) {
                    number.splice(0, 2, numberPrefix);
                }
            } else {
                //Add code country separators
                if (getCountyType(prevCountry) === 1)
                    number.splice(0, 1, numberPrefix);
                else if (getCountyType(prevCountry) === 2)
                    number.splice(0, 3, numberPrefix);
                else if (getCountyType(prevCountry) === 3) {
                    number.splice(0, 2, numberPrefix);
                }
            }

            //Add first number separators
            if (number.length > 4) {
                number.splice(4, 0, ' ');
            }

            //Add second number separators
            if (number.length > 8) {
                number.splice(8, 0, ' ');
            }

            //Add third separator for USA
            if (number.length > 13) {
                number.splice(13, 0, ' ');
            }

            function getCountyType(cntr) {
                if (cntr === 'US' || cntr === 'CA')
                    return 1;
                else if (cntr === 'IE')
                    return 2;
                else if (cntr !== 'US' && cntr !== 'CA' && cntr !== 'IE')
                    return 3;
            }

            return number.join('');
            /* To String */
        };

        var getDefaultCountry = function () {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(function (position) {
                    var lat = position.coords.latitude;
                    var lng = position.coords.longitude;
                    var latlng = new window.google.maps.LatLng(lat, lng);
                    new window.google.maps.Geocoder().geocode({'latLng': latlng}, function (results, status) {
                        if (status === window.google.maps.GeocoderStatus.OK) {

                            /*Parse result to get country*/
                            for (var r in results) {
                                var types = results[r].types;

                                for (var t in types) {
                                    var type = types[0];

                                    if (type === 'country') {

                                        for (var c in phones) {
                                            if (c === results[r].formatted_address) {
                                                return c;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        return getCountryById('US');
                    });
                }, function (e) {
                    //Use Default Country
                    return getCountryById('US');
                });
            } else {
                //Use Default Country
                return getCountryById('US');
            }
        };

        var getCurrentCountry = function (scope) {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(function (position) {
                    var lat = position.coords.latitude;
                    var lng = position.coords.longitude;
                    var latlng = new window.google.maps.LatLng(lat, lng);
                    new window.google.maps.Geocoder().geocode({'latLng': latlng}, function (results, status) {
                        if (status === window.google.maps.GeocoderStatus.OK) {

                            //Parse result to get country
                            for (var r in results) {
                                var types = results[r].types;

                                for (var t in types) {
                                    var type = types[0];

                                    if (type === 'country') {

                                        for (var c in phones) {
                                            if (c === results[r].formatted_address) {
                                                setCountry(c, scope);
                                                setNumberPrefix(c, scope);
                                                return;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        setCountry('US', scope);
                        /* If no country match */
                        setNumberPrefix('US', scope);

                    });
                }, function (e) {
                    //Use Default Country
                    setCountry('US', scope);
                    setNumberPrefix('US', scope);
                });
            } else {
                //Use Default Country
                setCountry('US', scope);
                setNumberPrefix('US', scope);
            }
        };

        return {
            getCountryById: getCountryById,
            getCountries: getCountries,
            setCountry: setCountry,
            getCurrentCountry: getCurrentCountry,
            validateNumberByLength: validateNumberByLength,
            getNumberPrefix: getNumberPrefix,
            getCountryFromNumber: getCountryFromNumber,
            formNumber: formNumber,
            getDefaultCountry: getDefaultCountry,
            validateNumber: validateNumber
        };
    }

    var app = angular.module('legacy-app');
    app.factory('PhoneService', [PhoneService]);
}());